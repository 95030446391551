.header-block {
  height: 86vh;
  // width: 100%;
  padding-left: 8vw;
  padding-right: 8vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  color: $white;
  position: relative;
  h1 {
    padding-top: 24vh;
    text-align: center;
    color: $white;
    font-size: 3rem;
    margin-bottom: 16px;
    margin-top: 0;
  }
  h4 {
    color: $white;
    font-weight: lighter;
    text-align: center;
  }
}
  
  
.header-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;

  a {
    margin-right: 16px;
    color: $white;
    text-decoration: none;
    font-size: 1.75rem;
    transition: all ease-in-out 220ms;
    &:hover {
      text-decoration: underline;
      color: $primary-color;
      transform: scale3d(1.2, 1.2, 1.2);
      cursor: pointer;
    }
    
  } 
}


.parallax-image {
  /* Display and position the pseudo-element */
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  /* Move the pseudo-element back away from the camera,
   * then scale it back up to fill the viewport.
   * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
  transform: translateZ(-1px) scale(1.5);
  /* Force the background image to fill the whole element. */
  background-size: cover;
  /* Keep the image from overlapping sibling elements. */ 
  z-index: -1;
}

.header__scrolldown {
  position: absolute;
  font-size: 1.75rem;
  transition: color ease-in-out 220ms;
  left: 50%;
  transform: translateX(-50%);
  top: 75%;
  animation: MoveUpDown 2.5s ease-in infinite;

  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    top: 73%;
  }
  55% {
    top: 73%;
  }
  70% {
    top: 75%;
  }
  85% {
    top: 73%;
  }
}