.section {
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8vw;
  padding-right: 8vw;
  h4 {
    margin-bottom: 4px;
  }
}

.section--projects {
  background-color: $dark-grey;
  text-align: left;
  color: $white;
}

.project-list {
  display: flex;
  flex-direction: column;
}

.project-list__project {
  margin-top: 12px;
  line-height: 1.5rem;
}