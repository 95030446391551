body, html {
  font-family: $sans-serif-font;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h2 {
  margin-top: 0;
}

a {
  text-decoration: none;
  color: $primary-color;
  font-weight: bold;
  transition: color ease-in-out 220ms;
  &:hover {
      color: $primary-color--hover;
  }
}

img {
  max-width: 100%;
}

nav {
  width: 100%;
  background: $primary-color;

  a {
    color: $white;
    padding: 1rem;
    display: inline-block;
  }
}

.content-block {
  margin-left: 8vw;
  margin-right: 8vw;
}

.contact {
  color: black;
  text-decoration: none;
  font-size: 1.75rem;
}