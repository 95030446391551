.examples-block {
    // min-height: 74vh;
    background-color: $white;
    color: black;
    width: 100%;
  }
  .example-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1vw;
    margin-right: -1vw;
    .workexample {
      width: 50%;
      position: relative;
      height: 240px;
      width: 300px;
      background-size: cover;
      background-position: center;
      border-radius: 2px;
      margin: 1vw;
      .grey-filter {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        right: 0;
        bottom: 0%;
        background-color: rgba($color: #000000, $alpha: 0);
        transition: background-color ease-in-out 0.3s;
        text-align: center;
        .filter-text {
          transition: all ease-in-out 0.3s;
          color: transparent;
          font-size: 1rem;
          position: absolute;
          top: 30%;
          left: 0;
          right: 0;
          margin: 0 auto;
          // transition: all ease-in-out 0.3;
          // .arrow {
          //   // color: $primary-color;
          // }
        }
  
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.35);
          .filter-text {
            color: $white;
            transform: scale(1.1);
            
          }
        }
  
      }
  
    }
  }
  .examples-content-block {
    margin-left: 8vw;
    margin-right: 8vw;
    padding-top: 8vh;
    padding-bottom: 8vh;
    color: black;
    text-align: center;
    a {
      color: black;
      text-decoration: none;
      transition: color ease-in-out 220ms;
        &:hover {
          // text-decoration: underline;
          color: $primary-color; 
        }
    }
  }
  